<template>
	<div class="content">
		<Header :title="$t('lang.informationDetails_title')"></Header>
		<div class="make">
			<h3>{{this.details.title}}</h3>
			<h4>{{this.details.author}}</h4>
			<div v-html="this.details.content"></div>
			<p>作者：{{this.details.author}}</p>
			<p>{{this.details.createTime}}</p>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header";
	import HB from "@/assets/js/bridge";
	import { getAppConsultDetail } from "@/assets/api/informationApi.js";
	
	export default {
		components: {
			Header
		},
		data() {
			return {
				language: '',
				id: '',
				details: {}
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
			// 获取链接id
			this.id = this.$route.query.id;
		},
		mounted() {
			this.getAppConsultDetail();
		},
		methods: {
			getAppConsultDetail() {
				getAppConsultDetail({
					id: this.id
				}).then((res) => {
					console.log(res, '资讯详情');
					if (res.code == 0) {
						this.details = res.data;
					} else{
						this.Toast(res.message);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	// * {
	// 	font-family: PingFang;
	// }
	.make {
		width: 92%;
		margin: 0 auto;
		padding-bottom: 1rem;
	}
	h3 {
		font-size: 0.3rem;
		line-height: 0.5rem;
		color: #000000;
		padding-top: 0.2rem;
	}
	h4 {
		font-size: 0.24rem;
		font-weight: 400;
		color: #999999;
	}
	p {
		font-size: 0.24rem;
		color: #999999;
		text-align: right;
	}
</style>