import axios from 'axios';
import md5 from 'js-md5';
import { MessageBox, Indicator } from "mint-ui";
import { ASCIISORTKEY_SELF } from '@/assets/js/constant'
import HB from './bridge';
import JSEncrypt from 'jsencrypt';

const asciiSort = (json) => {
  let arr = [],
    _json = {},
    str = "",
    reg = /\,/;
  for (i in json) {
    if (json[i] !== "" && json[i] !== undefined) {
      arr.push(i);
    }
  }
  arr.sort();
  for (var i = 0; i < arr.length; i++) {
    var name = arr[i];
    str += name + "=" + json[name] + "&";
  }
  _json = str + "key=" + ASCIISORTKEY_SELF;
  return _json;
}
// 创建axios实例
const service = axios.create({
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'X-Language': localStorage.getItem("lang"),
    "miniProgram": "ios", //新增的判断为安卓还是ios
  },
  timeout: 100000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  if (!config.params) {
    config.params = {}
  }
  config.headers['sec'] = md5(asciiSort(config.params));
  if (config.headers['content-type']) {
    config.headers['content-type'] = config.headers['Content-Type']
  }
	
	var encryptStr = new JSEncrypt()
	var pubKey=`-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDE3ZfgqGO6oa7xzgQ7/wKBEX3nFJRVOB9w5dZgEH6g2MR+KGhmCE7vQ/BJ0psROjUCbs5YCkyVqlmkS0uW1J299KTsEK81UvZxYVZmN28lhSWx2PmVaME+kBzMWpoDU8/F3rBzhJ+ZJ0OB+gNcyBr5Hak2ciTOn3ZOTXlmEv6X/wIDAQAB-----END PUBLIC KEY-----`
	encryptStr.setPublicKey(pubKey) // 设置 加密公钥
	var timestamp = Date.parse(new Date());
	var version = 1;
	var sg = timestamp + "," + version;
	var signdata = encryptStr.encrypt(sg) // 进行加密
	
	config.headers = {
	  'Content-Type': "application/json",
	  'X-Language': localStorage.getItem("lang"),
	  'X-Token': ''
	}
  console.log(config, '请求信息')
  return config;
}, error => {
  console.log(123)
  return Promise.reject(error);
})

service.interceptors.response.use(result => {
  //   if (result.data.state && result.data.state != 1 && result.data.msg) {
  //     if (result.data.state == 900108 || result.data.state == 900003 || result.data.state == 10000 || result
  //       .data.state == 10006) {
  //       MessageBox.alert(decodeURIComponent(result.data.msg)).then(res => {
  //         HB.ReloginAction(result.data)
  //       })
  //     } else {
  //       MessageBox.alert(decodeURIComponent(result.data.msg))
  //     }
  //     Indicator.close()
  //     return Promise.reject(result)
  //   } else {
  //     return result.data
  //   }
  // }, error => {
  //   console.log(error, 77)
  //   if (error.response.data && error.response.data.code != 200) {
  //     Indicator.close()
  //     MessageBox.alert(JSON.stringify(error.response.data.msg));
  //   }
  //   return Promise.reject(error)
  return result.data
})

export default service;