<template>
  <div class="header-wrap">
  </div>
</template>
<script>
import HB from "@/assets/js/bridge";
export default {
  props: {
    title: {
      default: "",
      type: String
    }
  },
  watch: {
    title: {
      handler(val) {
        HB.changeTitle(val);
      },
      immediate: true
    }
  },
  data() {
    return {};
  },
  mounted() {
    // HB.showNavbar()
    // HB.hiddenShareBtn();
  }
};
</script>
<style lang="scss" scoped>
.header-wrap {
}
</style>